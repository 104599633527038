import dynamic, { DynamicOptions } from 'next/dynamic'
import { BannerCarouselProps } from '@/views/WatchLandingView/BannerCarousel/BannerCarousel'
import { BannerCarouselPlaceholder } from './BannerCarouselPlaceholder'

// Because we take the data from static gen and manipulate the rendered values
// on the client side we need to make this dynamic to avoid the hydration errors.
export const BannerCarousel = dynamic(
  import('./BannerCarousel')
    .then((mod) => mod.BannerCarousel)
    .catch((err) =>
      // eslint-disable-next-line no-console
      console.error(`Failed to load the BannerCarousel!`, err),
    ) as DynamicOptions<BannerCarouselProps>,
  { ssr: false, loading: () => <BannerCarouselPlaceholder /> },
)
