import { FC } from 'react'
import { ContentExperiment } from '@/page/ContentExperiment'
import { ContentItem } from '@/page/PageContent/ContentItem'
import { isContentExperimentModel, isInlineExperimentModel, PageDataContext } from '@/services/RenderService'
import { InlineExperimentContentItemsItem, Maybe, PageContentItem } from '@/types/codegen-contentful'
import { InlineExperiment } from '../InlineExperiment'

interface Props {
  content?: Maybe<PageContentItem>
  pageDataContext: PageDataContext
}

export const PageContent: FC<Props> = ({ content, pageDataContext }) => {
  if (!content) return null

  if (isInlineExperimentModel(content) && content.experimentName && content.contentItemsCollection?.items) {
    return (
      <InlineExperiment
        experimentName={content.experimentName}
        contentItems={content.contentItemsCollection.items as InlineExperimentContentItemsItem[]}
        pageDataContext={pageDataContext}
      />
    )
    // TODO: remove ContentExperiment after we move away from Optimizely
  } else if (isContentExperimentModel(content)) {
    return <ContentExperiment content={content} pageDataContext={pageDataContext} />
  } else {
    return <ContentItem content={content} pageDataContext={pageDataContext} />
  }
}
