import { ContentfulRichText } from '@/atoms/ContentfulRichText'
import { isHasTypeName } from '@/services/RenderService'
import { TextBlock as TextBlockType } from '@/types/codegen-contentful'

export function isTextBlock(content: unknown): content is TextBlockType {
  return isHasTypeName(content) && content.__typename === 'TextBlock'
}

interface TextBlockProps {
  textBlock: TextBlockType
}
export function TextBlock(props: TextBlockProps) {
  return <ContentfulRichText json={props.textBlock.text?.json} className="max-w-full" />
}
