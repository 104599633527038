import dynamic, { DynamicOptions } from 'next/dynamic'
import { FadeInExperimentProps } from './FadeInExperiment'

export const FadeInExperiment = dynamic(
  import('./FadeInExperiment')
    .then((mod) => mod.FadeInExperiment)
    .catch((err) =>
      // eslint-disable-next-line no-console
      console.error(`Failed to load the FadeInExperiment!`, err),
    ) as DynamicOptions<FadeInExperimentProps>,
  { ssr: false },
)
