import React from 'react'
import { IconColor, getHexColor } from '../utils'

interface DeprecatedIconProps {
  className?: string
  color?: IconColor
  size?: number
  height?: number
  width?: number
  onClick?: React.MouseEventHandler<SVGSVGElement>
}

/**
 * @deprecated Use the CaretRightIcon instead.
 */
export const ChevronRightIcon: React.FC<DeprecatedIconProps> = ({
  size,
  width = 9,
  height = 14,
  color = 'white',
  className,
  onClick,
}) => {
  return (
    <svg
      data-testid="chevron-right-icon"
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path d="M1 13L7 7L1 1" stroke={getHexColor(color)} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}
