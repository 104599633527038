import { FC } from 'react'
import { DateTime } from 'luxon'
import { Else, If, Then, When } from 'react-if'
import { AsDiv, CaptionSM } from '@/atoms/Text'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'

interface Props {
  live: boolean
  isEnded: boolean
  startAt: string
}

export const LivestreamTitle: FC<Props> = ({ live, startAt, isEnded }): JSX.Element => {
  const { t } = useTranslate('watch')
  const { locale } = useLocale()

  const dateFormatter = Intl.DateTimeFormat(locale, { dateStyle: 'medium' })
  const dateTimeFormatter = Intl.DateTimeFormat(locale, { dateStyle: 'medium', timeStyle: 'short' })

  return (
    <div className="mr-2">
      <CaptionSM as={AsDiv} color="core-gray-400">
        <If condition={live}>
          <Then>
            <div className="flex items-center">
              <div className="mr-1 h-2 w-2 rounded-full bg-red-500" />
              <span>{t('liveNow', 'Live Now')}</span>
            </div>
          </Then>
          <Else>
            {Boolean(!isEnded && startAt) && (
              <span>
                {t('liveOnDate', 'Live on {{dateDisplay}}', {
                  dateDisplay: dateTimeFormatter.format(DateTime.fromISO(startAt as string).toJSDate()),
                })}
              </span>
            )}
            <When condition={isEnded}>
              <span>
                {t('airedOn', 'Aired on {{dateDisplay}}', {
                  dateDisplay: dateFormatter.format(DateTime.fromISO(startAt as string).toJSDate()),
                })}
              </span>
            </When>
          </Else>
        </If>
      </CaptionSM>
    </div>
  )
}
