import { When } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { useGuildUser } from '@/services/GuildUserService'
import { isHasTypeName } from '@/services/RenderService'
import { CallToAction as CallToActionType } from '@/types/codegen-contentful'

export function isCallToAction(content: unknown): content is CallToActionType {
  return isHasTypeName(content) && content.__typename === 'CallToAction'
}

interface CallToActionProps {
  callToAction: CallToActionType
}
export function CallToAction(props: CallToActionProps) {
  const { isGuildMember } = useGuildUser()
  const canShowNonGuildCallToAction = !isGuildMember && props.callToAction.buttonHref && props.callToAction.buttonText
  const canShowGuildCallToAction =
    isGuildMember && props.callToAction.guildButtonHref && props.callToAction.guildButtonText

  return (
    <>
      <When condition={canShowNonGuildCallToAction}>
        <LinkButton href={props.callToAction.buttonHref as string} variant="oxide" className="w-full md:w-fit">
          {props.callToAction.buttonText}
        </LinkButton>
      </When>
      <When condition={canShowGuildCallToAction}>
        <LinkButton href={props.callToAction.guildButtonHref as string} variant="oxide" className="w-full md:w-fit">
          {props.callToAction.guildButtonText}
        </LinkButton>
      </When>
    </>
  )
}
