import dynamic, { DynamicOptions } from 'next/dynamic'
import { PlaceholderLandscapeCollection } from '@/organisms/PlaceholderLandscapeCollection'
import { WatchNowCollectionProps } from '@/organisms/WatchNowCollection/WatchNowCollection'

export const WatchNowCollection = dynamic(
  import('./WatchNowCollection')
    .then((mod) => mod.WatchNowCollection)
    .catch((err) =>
      // eslint-disable-next-line no-console
      console.error(`Failed to load the WatchNowCollection!`, err),
    ) as DynamicOptions<WatchNowCollectionProps>,
  { ssr: false, loading: () => <PlaceholderLandscapeCollection /> },
)
