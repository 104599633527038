import React from 'react'
import classNames from 'classnames'
import { HorizontalBannerScroller } from '@/molecules/HorizontalBannerScroller'

export const BannerCarouselPlaceholder = () => {
  return (
    <HorizontalBannerScroller>
      <div
        className={classNames(
          'banner-content-container relative w-full cursor-pointer rounded-xl border-4 border-transparent leading-[0] transition-all duration-500 hover:border-white',
        )}
      >
        <div className={classNames('hidden rounded-lg 2xl:block animate-pulse bg-black')}>
          <div className="aspect-h-1 aspect-w-4" />
        </div>
        <div className={classNames('hidden rounded-lg lg:block 2xl:hidden animate-pulse bg-black')}>
          <div className="aspect-h-1 aspect-w-4" />
        </div>
        <div className={classNames('rounded-lg lg:hidden animate-pulse bg-black')}>
          <div className="aspect-h-6 aspect-w-14" />
        </div>
      </div>
    </HorizontalBannerScroller>
  )
}
