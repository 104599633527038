import React from 'react'
import { NextSeo } from 'next-seo'
import { fullPublisher } from '@/constants/seo'
import { DEFAULT_SEO_VALUES } from '@/layout/Seo/Default'
import { ViewActionJsonLd } from '@/layout/Seo/JsonLd/ViewActionJsonLd'
import { WebPageJsonLd } from '@/layout/Seo/JsonLd/WebPageJsonLd'
import { Page } from '@/types/codegen-contentful'
import { buildCloudinaryImageUrlFromContentfulObject } from '@/utils/Cloudinary'
import { reportErrorToBugsnag } from '@/utils/bugsnag'
import { formatCanonicalUrl } from '@/utils/url'

interface PageSeoProps {
  noindex?: boolean
  nofollow?: boolean
  page: Page
  locale: string
}
export function PageSeo(props: PageSeoProps) {
  const { locale, page, noindex = false, nofollow = false } = props
  const title = page?.seo?.title ?? DEFAULT_SEO_VALUES.defaultTitle
  const description = page?.seo?.description ?? DEFAULT_SEO_VALUES.description
  let path = page?.path

  if (!path) {
    reportErrorToBugsnag(`PageSeo: path is missing on a page object from Contentful! Id: ${page?.sys?.id}`)
    path = ''
  }

  const canonical = formatCanonicalUrl({ locale, path })
  const image = buildCloudinaryImageUrlFromContentfulObject(page?.seo?.metaImage2?.[0], 'w_1200')
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={canonical}
        openGraph={{
          description,
          images: [
            {
              alt: description,
              height: 630,
              width: 1200,
              url: image,
            },
          ],
          site_name: 'Angel Studios',
          title,
          url: canonical,
        }}
        facebook={{
          appId: '208285917998450',
        }}
        noindex={noindex}
        nofollow={nofollow}
      />
      {/* TODO: support hreflangs */}
      <WebPageJsonLd
        id={`${canonical}#webpage`}
        url={canonical}
        image={image}
        inLanguage="en"
        name={title}
        headline={title}
        description={description}
        isPartOf={{
          type: 'WebSite',
          id: 'https://www.angel.com/#website',
          url: 'https://www.angel.com/',
          name: 'Angel Studios',
          publisher: fullPublisher,
        }}
      />
      <ViewActionJsonLd
        keyOverride="iOS"
        target={{ type: 'EntryPoint', urlTemplate: 'ios-app://1473663873/https/www.angel.com/' }}
      />
      <ViewActionJsonLd
        keyOverride="Android"
        target={{ type: 'EntryPoint', urlTemplate: 'android-app://com.vidangel.thechosen/https/www.angel.com/' }}
      />
    </>
  )
}
