import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'
import { options } from '@/services/ContentfulService'

interface ContentfulRichTextProps {
  className?: string
  json?: Document
}

export const ContentfulRichText: React.VFC<ContentfulRichTextProps> = ({ className, json }) => {
  if (!json) return null

  return <div className={className}>{documentToReactComponents(json, options)}</div>
}
