import React, { FC, Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'
import LazyHydrate from 'react-lazy-hydration'
import { PlayCircleIcon } from '@/atoms/Icons/PlayCircleIcon'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { paths } from '@/constants'
import { LandscapeRowCollection, LandscapeTileCard } from '@/molecules/LandscapeRowCollection'
import { LivestreamThumbnailPill } from '@/molecules/LivestreamThumbnailPill'
import { PlaceholderLandscapeCollection } from '@/organisms/PlaceholderLandscapeCollection'
import { LivestreamTitle } from '@/page/LivestreamLandscapeRowCollection/LivestreamTitle'
import { getAllProjectLivestreamsSorted, Livestream } from '@/services/LivestreamService'
import { LinkViewModel } from '@/services/RenderService'
import { useLocale } from '@/utils/LocaleUtil'
import { getProjectTheme } from '@/utils/project-themes'

interface Props {
  title: string
  testId?: string
}

export const LivestreamLandscapeRowCollection: FC<Props> = ({ testId, title }) => {
  const { locale } = useLocale()
  const [livestreams, setLivestreams] = useState<Livestream[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getAllProjectLivestreamsSorted({ locale }).then((data) => {
      setLivestreams(data)
      setIsLoading(false)
    })
  }, [locale])

  if (isLoading) {
    return <PlaceholderLandscapeCollection />
  }

  /* We get Livestreams client side so handle hydration */
  return (
    <LazyHydrate as="Fragment" whenVisible>
      <LandscapeRowCollection testId={testId} title={title}>
        {livestreams?.map((item) => {
          if (!item) return null
          const { name, guid, live, startAt, isEnded, bannerCloudinaryPath, projectSlug } = item
          const { name: projectName } = getProjectTheme(projectSlug as string)

          const linkModel: LinkViewModel = {
            name: name,
            imageUrl: bannerCloudinaryPath,
            alt: name,
            linkUrl: `${paths.livestream.index}/${guid}`,
            label: name,
          }

          return (
            <Fragment key={guid}>
              <LandscapeTileCard
                className={classNames('mb-2 mr-4', { 'rounded-xl border-4 border-red-500': live })}
                linkModel={linkModel}
              >
                <div className="absolute inset-0">
                  <div className="flex h-full w-full items-center justify-center opacity-0 duration-200 hover:bg-[#00000088] hover:opacity-100">
                    <div className="mx-auto h-fit w-fit !opacity-100">
                      <PlayCircleIcon />
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-2 right-2">
                  <LivestreamThumbnailPill
                    live={live}
                    isEnded={isEnded}
                    startAt={startAt}
                    isGuildOnly={item.unavailableReason === 'PRERELEASE'}
                  />
                </div>
              </LandscapeTileCard>
              <TitleAndSubtitleDisplay className="mr-4" title={projectName} subtitle={name} />
              <LivestreamTitle live={live} isEnded={isEnded} startAt={startAt} />
            </Fragment>
          )
        })}
      </LandscapeRowCollection>
    </LazyHydrate>
  )
}
