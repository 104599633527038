import React from 'react'
import { ResponsiveObject } from 'react-slick'
import { Placeholder } from '@/atoms/Placeholder'
import { AsH1, TitleLG } from '@/atoms/Text'
import { HorizontalItemScroller } from '@/molecules/HorizontalItemScroller'
import { LinkViewModel } from '@/services/RenderService'
import { createPlaceholderArray } from '@/utils/PlaceholderArrays'
import { SquareTile as SquareTileCard } from './SquareTile'

const responsiveSlick: ResponsiveObject[] = [
  {
    breakpoint: 1536,
    settings: {
      slidesToScroll: 6,
      slidesToShow: 6,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesToScroll: 5,
      slidesToShow: 5,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 640,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
  },
]

export interface Props {
  title?: string
  contentLinks?: LinkViewModel[]
}

const PLACEHOLDERS = createPlaceholderArray(8)

export const SquareCollection: React.FC<Props> = React.memo(({ contentLinks, title }) => {
  const sorted = contentLinks?.sort((a, b) => (a?.sortOrder || 0) - (b?.sortOrder || 0)) || []

  return (
    <div data-testid="square-collection">
      {title && (
        <header>
          <TitleLG weight="bold" as={AsH1} data-testid="project-tiles-header" className="mb-3 md:mb-4">
            {title}
          </TitleLG>
        </header>
      )}
      <HorizontalItemScroller slidesToShow={8} slidesToScroll={8} responsive={responsiveSlick}>
        {sorted.length === 0 &&
          PLACEHOLDERS.map((v) => {
            return (
              <div key={v} className="pr-4">
                <Placeholder className="aspect-h-1 aspect-w-1 rounded-lg" />
              </div>
            )
          })}

        {contentLinks
          ?.filter((contentLink) => contentLink?.imageUrl)
          ?.map((contentLink) => {
            return <SquareTileCard key={contentLink.imageUrl} contentLink={contentLink} />
          })}
      </HorizontalItemScroller>
    </div>
  )
})

SquareCollection.displayName = 'React.memo(SquareCollection)'
