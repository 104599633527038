import { isHasTypeName } from '@/services/RenderService'
import { Header as HeaderType } from '@/types/codegen-contentful'

export function isHeader(content: unknown): content is HeaderType {
  return isHasTypeName(content) && content.__typename === 'Header'
}

interface HeaderProps {
  header: HeaderType
}
export function Header(props: HeaderProps) {
  const Level = `${props.header.level ?? 'h2'}` as keyof JSX.IntrinsicElements
  const size = props.header.size ?? 'text-2xl'
  const color = props.header.color ?? 'text-white'
  const align = props.header.align ?? 'text-left'

  return (
    <header>
      <Level className={`${size} ${color} ${align} m-0 p-0 font-whitney font-bold !leading-[1.25] -tracking-[0.01em]`}>
        {props.header.headerText}
      </Level>
    </header>
  )
}
