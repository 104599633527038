import React, { FC } from 'react'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { GuildScore } from '@/atoms/GuildScore'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { LandscapeRowCollection, LandscapeTileCard } from '@/molecules/LandscapeRowCollection'
import { PortraitRowCollection } from '@/molecules/PortraitRowCollection'
import {
  buildLinkModel,
  buildLinkTabModel,
  buildLinkTabUrl,
  buildLinkUrl,
  buildSubtitle,
  buildTabSubtitle,
  shortenMonthInHeader,
} from '@/services/ComingSoonService'
import { ComingSoonTitle, ComingSoonNode } from '@/services/ComingSoonService'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { Grid } from '../CollectionDisplay/Grid'
import { LargeLandscapeLink } from '../CollectionDisplay/LargeLandscapeLink'
import { LargePortraitLink } from '../CollectionDisplay/LargePortraitLink'

interface ComingSoonCollectionProps {
  title: string
  enableWatchProgress?: boolean
  ComingSoonTitleCollection?: ComingSoonTitle[]
  ComingSoonTabCollection?: ComingSoonNode[]
  testId?: string
  layout?: 'portrait-grid' | 'portrait-row'
}

const RESPONSIVE = [
  {
    breakpoint: 1700,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 1536,
    settings: {
      slidesToScroll: 3,
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesToScroll: 3,
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 640,
    settings: {
      slidesToScroll: 1,
      slidesToShow: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToScroll: 1,
      slidesToShow: 1,
    },
  },
]

export const ComingSoonCollection: FC<ComingSoonCollectionProps> = ({
  title,
  ComingSoonTitleCollection,
  ComingSoonTabCollection,
  testId,
  layout = 'portrait-row',
}) => {
  const { t } = useTranslate('watch')
  const { locale } = useLocale()
  const canDisplay = ComingSoonTitleCollection && ComingSoonTitleCollection?.length > 0

  return (
    <>
      <When condition={canDisplay && layout === 'portrait-grid'}>
        <LazyHydrate whenVisible>
          <GBFadeInExperiment defaultValue={false} experimentName="coming_soon_rail_updates">
            <ExperimentVariation variation={true}>
              <Grid>
                {ComingSoonTabCollection?.map((item) => {
                  if (!item) return null

                  const linkModel = buildLinkTabModel(item)
                  if (!linkModel) return null

                  const title = item.title ?? item?.watchable?.name ?? item?.project?.name
                  const subTitle = buildTabSubtitle(item, t)

                  const header = item.header && shortenMonthInHeader(item.header)

                  return (
                    <LazyHydrate key={item?.id} whenVisible>
                      <>
                        <div className="flex flex-col">
                          <div className="mb-2 mr-4">
                            <LargeLandscapeLink link={{ ...linkModel, linkUrl: buildLinkTabUrl(item) }} />
                          </div>
                          <TitleAndSubtitleDisplay
                            className="mr-4"
                            title={title || undefined}
                            subtitle={
                              header && item.focus
                                ? `${subTitle} ${header}`
                                : header
                                ? `${subTitle} ${header}`
                                : subTitle
                            }
                          />
                          <When condition={item?.project?.highestScore}>
                            <GuildScore guildScore={item?.project?.highestScore} />
                          </When>
                        </div>
                      </>
                    </LazyHydrate>
                  )
                })}
              </Grid>
            </ExperimentVariation>
            <ExperimentVariation variation={false}>
              <Grid>
                {ComingSoonTitleCollection?.map((item) => {
                  if (!item) return null

                  const linkModel = buildLinkModel(item)
                  if (!linkModel) return null

                  const title = item.name
                  const subTitle = buildSubtitle(item, t, locale)

                  return (
                    <LazyHydrate key={item?.id} whenVisible>
                      <div className="flex flex-col">
                        <div className="mb-2 mr-4">
                          <LargePortraitLink link={{ ...linkModel, linkUrl: buildLinkUrl(item) }} />
                        </div>
                        <TitleAndSubtitleDisplay className="mr-4" title={title} subtitle={subTitle} />
                        <When condition={item?.highestScore}>
                          <GuildScore guildScore={item.highestScore} />
                        </When>
                      </div>
                    </LazyHydrate>
                  )
                })}
              </Grid>
            </ExperimentVariation>
            <ExperimentVariation default>
              <Grid>
                {ComingSoonTitleCollection?.map((item) => {
                  if (!item) return null

                  const linkModel = buildLinkModel(item)
                  if (!linkModel) return null

                  const title = item.name
                  const subTitle = buildSubtitle(item, t, locale)

                  return (
                    <LazyHydrate key={item?.id} whenVisible>
                      <div className="flex flex-col">
                        <div className="mb-2 mr-4">
                          <LargePortraitLink key={item.name} link={{ ...linkModel, linkUrl: buildLinkUrl(item) }} />
                        </div>
                        <TitleAndSubtitleDisplay className="mr-4" title={title} subtitle={subTitle} />
                        <When condition={item?.highestScore}>
                          <GuildScore guildScore={item.highestScore} />
                        </When>
                      </div>
                    </LazyHydrate>
                  )
                })}
              </Grid>
            </ExperimentVariation>
          </GBFadeInExperiment>
        </LazyHydrate>
      </When>
      <When condition={canDisplay && layout === 'portrait-row'}>
        <LazyHydrate whenVisible>
          <>
            <GBFadeInExperiment defaultValue={false} experimentName="coming_soon_rail_updates">
              <ExperimentVariation variation={true}>
                <LandscapeRowCollection
                  testId={testId}
                  title={title}
                  slidesToScroll={4}
                  slidesToShow={4}
                  responsiveSlick={RESPONSIVE}
                >
                  {ComingSoonTabCollection?.map((item) => {
                    if (!item) return null

                    const linkModel = buildLinkTabModel(item)
                    if (!linkModel) return null

                    const title = item.title ?? item?.watchable?.name ?? item?.project?.name
                    const subTitle = buildTabSubtitle(item, t)

                    const header = item.header && shortenMonthInHeader(item.header)

                    return (
                      <LazyHydrate key={item?.id} whenVisible>
                        <>
                          <div className="mb-2 mr-4">
                            <LandscapeTileCard linkModel={linkModel} />
                          </div>
                          <TitleAndSubtitleDisplay
                            className="mr-4"
                            title={title || undefined}
                            subtitle={
                              header && item.focus
                                ? `${subTitle} ${header}`
                                : header
                                ? `${subTitle} ${header}`
                                : subTitle
                            }
                          />
                          <When condition={item?.project?.highestScore}>
                            <GuildScore guildScore={item?.project?.highestScore} />
                          </When>
                        </>
                      </LazyHydrate>
                    )
                  })}
                </LandscapeRowCollection>
              </ExperimentVariation>
              <ExperimentVariation variation={false}>
                <PortraitRowCollection testId={testId} title={title} slidesToScroll={6} slidesToShow={6} haltLazyLoad>
                  {ComingSoonTitleCollection?.map((item) => {
                    if (!item) return null

                    const linkModel = buildLinkModel(item)
                    if (!linkModel) return null

                    const title = item.name
                    const subTitle = buildSubtitle(item, t, locale)

                    return (
                      <LazyHydrate key={item?.id} whenVisible>
                        <>
                          <div className="mb-2 mr-4">
                            <LargePortraitLink link={{ ...linkModel, linkUrl: buildLinkUrl(item) }} />
                          </div>
                          <TitleAndSubtitleDisplay className="mr-4" title={title} subtitle={subTitle} />
                          <When condition={item?.highestScore}>
                            <GuildScore guildScore={item?.highestScore} />
                          </When>
                        </>
                      </LazyHydrate>
                    )
                  })}
                </PortraitRowCollection>
              </ExperimentVariation>
              <ExperimentVariation default>
                <PortraitRowCollection testId={testId} title={title} slidesToScroll={6} slidesToShow={6} haltLazyLoad>
                  {ComingSoonTitleCollection?.map((item) => {
                    if (!item) return null

                    const linkModel = buildLinkModel(item)
                    if (!linkModel) return null

                    const title = item.name
                    const subTitle = buildSubtitle(item, t, locale)

                    return (
                      <LazyHydrate key={item?.id} whenVisible>
                        <>
                          <div className="mb-2 mr-4">
                            <LargePortraitLink link={{ ...linkModel, linkUrl: buildLinkUrl(item) }} />
                          </div>
                          <TitleAndSubtitleDisplay className="mr-4" title={title} subtitle={subTitle} />
                          <When condition={item?.highestScore}>
                            <GuildScore guildScore={item?.highestScore} />
                          </When>
                        </>
                      </LazyHydrate>
                    )
                  })}
                </PortraitRowCollection>
              </ExperimentVariation>
            </GBFadeInExperiment>
          </>
        </LazyHydrate>
      </When>
    </>
  )
}
