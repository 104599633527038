import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { arrow, flip, offset, Placement, shift, Side, useFloating } from '@floating-ui/react-dom'
import { Popover } from '@headlessui/react'
import classNames from 'classnames'
import { useSafeTrack } from '@/utils/analytics'

export interface GuildTooltipProps {
  iconSize?: number
  className?: string
  buttonClassName?: string
  panelClassName?: string
  panelPosition?: 'LEFT' | 'CENTER' | 'RIGHT'
  isIconOnly?: boolean
  removeBorder?: boolean
  buttonStyle?: React.CSSProperties
  guildScore?: number
}

interface GuildTooltipComponentProps extends GuildTooltipProps {
  trigger: ReactNode
  content: ReactNode
}

export const GuildTooltip: React.FC<GuildTooltipComponentProps> = ({
  className,
  buttonClassName,
  panelClassName,
  panelPosition = 'CENTER',
  trigger,
  content,
  isIconOnly,
  removeBorder = false,
  buttonStyle,
  guildScore,
}) => {
  const [toolTipStartedAt, setToolTipStartedAt] = useState<Date | null>(null)
  const [isHovering, setIsHovering] = useState(false)
  const arrowRef = React.useRef<HTMLDivElement>(null)
  const track = useSafeTrack()

  const handleHoverStart = useCallback(() => {
    setIsHovering(true)
    setToolTipStartedAt(new Date())
    track('Guild Score Tooltip Opened', { guildScore })
  }, [setIsHovering, setToolTipStartedAt, track, guildScore])

  const handleHoverStopped = useCallback(() => {
    setIsHovering(false)
    const started = toolTipStartedAt ?? new Date()
    const openForMilliseconds = Date.now() - started.getTime()
    track('Guild Score Tooltip Closed', { guildScore, openForMilliseconds })
    setToolTipStartedAt(null)
  }, [setIsHovering, toolTipStartedAt, setToolTipStartedAt, track, guildScore])

  const placement: Placement =
    panelPosition === 'LEFT' ? 'bottom-start' : panelPosition === 'RIGHT' ? 'bottom-end' : 'top'

  const { x, y, refs, strategy, middlewareData } = useFloating({
    placement,
    middleware: [offset(8), flip(), shift({ padding: 8 }), arrow({ element: arrowRef })],
  })

  const staticSide = useMemo(() => {
    const side = placement.split('-')[0] as Side
    return {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[side] as string
  }, [placement])

  return (
    <Popover className={classNames('relative', className)}>
      {({ open }) => (
        <>
          <Popover.Button
            ref={refs.setReference}
            onMouseEnter={handleHoverStart}
            onMouseLeave={handleHoverStopped}
            className={classNames(
              'flex gap-1 items-center justify-center relative rounded-full py-0.5 @sm:py-1 px-3',
              !removeBorder && !isIconOnly && 'border border-white/30 ',
              buttonClassName,
            )}
            style={buttonStyle}
          >
            {trigger}
          </Popover.Button>
          {(open || isHovering) && (
            <div
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: 'calc(100vw - 2rem)',
                maxWidth: '320px',
              }}
              className={classNames('p-6 !z-[60] text-left bg-white shadow-light-2 rounded-2xl', panelClassName)}
            >
              <div
                ref={arrowRef}
                className="absolute h-3 w-3 rotate-45 bg-white"
                style={{
                  left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : '',
                  top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
                  right: '',
                  bottom: '',
                  [staticSide]: '-5px',
                }}
              />
              {content}
            </div>
          )}
        </>
      )}
    </Popover>
  )
}
