import React, { RefObject, useEffect, useRef } from 'react'
import styles from './HorizontalItemScroller.module.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import classNames from 'classnames'
import Slider, { InnerSlider, Settings, ResponsiveObject } from 'react-slick'
import { ChevronLeftIcon } from '@/atoms/Icons/ChevronLeftIcon'
import { ChevronRightIcon } from '@/atoms/Icons/ChevronRightIcon'
import { MediaCardProps } from '@/molecules/MediaCard/MediaCard'
import { useSupportsTouch } from '@/utils/ScreenUtils'

const defaultReactSlickResponsive: ResponsiveObject[] = [
  {
    breakpoint: 1536,
    settings: {
      slidesToScroll: 5,
      slidesToShow: 5,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToScroll: 3,
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToScroll: 2.5,
      slidesToShow: 2.5,
    },
  },
  {
    breakpoint: 640,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToScroll: 1.5,
      slidesToShow: 1.5,
    },
  },
]

export interface KeyedMediaCardProps extends MediaCardProps {
  key: string
  guid: string
}

interface ReactSlickArrowProps {
  currentSlide?: number
  slideCount?: number
  onClick?: () => void
  style?: Record<string, string | number>
}

interface InnerSliderWithPrivateVariables extends InnerSlider {
  props: Settings
}

export interface ArrowCompProps {
  hasItems: boolean
  style?: React.CSSProperties
  onClick?: () => void
}

export interface HorizontalItemScrollerProps extends Settings {
  wrapperClassName?: string
  scrollerClassName?: string
  selectedIndex?: number
  goTo?: boolean
  NextArrowComp?: React.VFC<ArrowCompProps>
  PrevArrowComp?: React.VFC<ArrowCompProps>
  nextArrowClassName?: string
  previousArrowClassName?: string
  slickRef?: RefObject<Slider>
  haltLazyLoad?: boolean
}

const getChevronClassNames = (visible: boolean, supportsTouch: boolean, className?: string) =>
  classNames(
    'cursor-pointer max-w-12 min-w-12',
    'h-full w-6 sm:w-8 md:w-12 xl:w-16',
    'z-10 absolute top-0',
    'items-center justify-center',
    getVisibilityClass(visible, supportsTouch),
    styles.chevronGroup,
    className,
  )

const getVisibilityClass = (isVisible: boolean, supportsTouch: boolean): string =>
  classNames('lg:!hidden', {
    '!hidden !lg:flex lg:group-hover:!flex': isVisible && supportsTouch,
    '!flex lg:group-hover:!flex': isVisible && !supportsTouch,
    '!hidden': !isVisible,
  })

export const HorizontalItemScroller: React.FC<HorizontalItemScrollerProps> = ({
  children,
  wrapperClassName,
  scrollerClassName,
  NextArrowComp,
  PrevArrowComp,
  nextArrowClassName,
  previousArrowClassName,
  responsive = defaultReactSlickResponsive,
  slidesToShow = 5,
  slidesToScroll = 5,
  selectedIndex = 0,
  goTo = false,
  slickRef,
  haltLazyLoad = false,
  ...props
}) => {
  const supportsTouch = useSupportsTouch()
  const slider = useRef<Slider>(null)
  const hasItems = Boolean(React.Children.count(children) > 0)

  const infinite = props.infinite

  const ReactSlickChevronRight: React.FC<ReactSlickArrowProps> = (props) => {
    const { currentSlide, slideCount, onClick, style } = props
    const innerSlider = slider?.current?.innerSlider as InnerSliderWithPrivateVariables

    let canGo = hasItems
    if (
      !infinite &&
      innerSlider?.props?.slidesToShow &&
      slideCount &&
      currentSlide &&
      (slideCount <= innerSlider?.props?.slidesToShow || currentSlide >= slideCount - innerSlider?.props?.slidesToShow)
    ) {
      canGo = false
    }

    return NextArrowComp ? (
      <NextArrowComp hasItems={canGo} style={style} onClick={onClick} />
    ) : (
      <div
        className={getChevronClassNames(canGo, supportsTouch, classNames('block right-4 group', nextArrowClassName))}
        onClick={onClick}
      >
        <div
          className={classNames(
            'flex items-center justify-items-center',
            'bg-black/25',
            'p-3 rounded-full h-10 w-10',
            styles.chevronCircle,
            !supportsTouch ? 'flex' : 'hidden lg:flex',
          )}
        >
          <ChevronRightIcon className="pl-1" height={28} width={18} />
        </div>
      </div>
    )
  }

  const ReactSlickChevronLeft: React.FC<ReactSlickArrowProps> = (props) => {
    const { currentSlide, onClick, style } = props

    const canGo = hasItems && (infinite || Boolean(currentSlide && currentSlide > 0))

    return PrevArrowComp ? (
      <PrevArrowComp hasItems={canGo} style={style} onClick={onClick} />
    ) : (
      <div
        className={getChevronClassNames(canGo, supportsTouch, classNames('left-0', previousArrowClassName))}
        onClick={onClick}
      >
        <div
          className={classNames(
            'flex items-center justify-items-center',
            'bg-black/25',
            'p-3 rounded-full h-10 w-10',
            styles.chevronCircle,
            !supportsTouch ? 'flex' : 'hidden lg:flex',
          )}
        >
          <ChevronLeftIcon className="pr-1" size={28} />
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (goTo) {
      slider?.current?.slickGoTo(selectedIndex)
    }
  }, [goTo, selectedIndex])

  return (
    <div className="group">
      <div className={wrapperClassName}>
        <Slider
          className={scrollerClassName}
          swipe
          touchMove
          useCSS
          useTransform
          waitForAnimate
          adaptiveHeight={false}
          ref={typeof slickRef === 'undefined' ? slider : slickRef}
          initialSlide={0}
          infinite={false}
          swipeToSlide={false}
          touchThreshold={5}
          cssEase="ease"
          easing="easeQuadOut"
          lazyLoad={!haltLazyLoad ? 'progressive' : undefined}
          edgeFriction={0.35}
          centerPadding="50px"
          speed={300}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToScroll}
          responsive={responsive}
          nextArrow={<ReactSlickChevronRight onClick={slider?.current?.slickNext} />}
          prevArrow={<ReactSlickChevronLeft onClick={slider?.current?.slickPrev} />}
          {...props}
        >
          {children}
        </Slider>
      </div>
    </div>
  )
}
