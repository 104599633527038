import React from 'react'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { When } from 'react-if'
import { GuildIcon } from '@/atoms/Icons/GuildIcon'
import { LabelXS } from '@/atoms/Text'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'

interface LivestreamThumbnailPillProps {
  live: boolean
  isEnded: boolean
  startAt: string
  isGuildOnly: boolean
}

export const LivestreamThumbnailPill: React.FC<LivestreamThumbnailPillProps> = ({
  live,
  isEnded,
  startAt,
  isGuildOnly,
}) => {
  const { t } = useTranslate('common')
  const { locale } = useLocale()
  const airDate = DateTime.fromISO(startAt)

  if (isEnded && !isGuildOnly) return null
  if (live && !isGuildOnly) return null

  let text

  if (live) {
    text = t('live', 'Live')
  } else if (isEnded && isGuildOnly) {
    text = t('membersOnly', 'Members Only')
  } else {
    const dateTime = airDate.toLocaleString(DateTime.DATETIME_SHORT, { locale })
    text = t('upcoming', 'Upcoming', {
      dateTime,
    })
  }

  return (
    <div
      className={classNames(
        'rounded-full items-center w-fit px-3 py-1 flex gap-1 ml-auto backdrop-blur-xl bg-core-gray-950/80',
      )}
    >
      <When condition={isGuildOnly}>
        <GuildIcon color1="white" size={12} />
      </When>
      <LabelXS color="white" weight="semibold" className="uppercase">
        {text}
      </LabelXS>
    </div>
  )
}
