import { FC } from 'react'
import { PageContent } from '@/page/PageContent/PageContent'
import { PageDataContext, PageModel } from '@/services/RenderService/types'

interface Props {
  page: PageModel
  pageDataContext: PageDataContext
}

export const Page: FC<Props> = ({ page, pageDataContext }) => {
  if (!page || !pageDataContext) return null

  return (
    <>
      {page.contentCollection?.items.map((content) => {
        if (!content?.sys?.id) return null // this could be content that is not yet supported
        const key = `${content?.__typename}-${content?.sys?.id}`
        return content ? <PageContent key={key} content={content} pageDataContext={pageDataContext} /> : null
      })}
    </>
  )
}
