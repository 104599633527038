import React, { FC } from 'react'
import LazyHydrate from 'react-lazy-hydration'
import { PlayButtonThumbnailOverlay } from '@/atoms/PlayButtonThumbnailOverlay'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { LandscapeRowCollection, LandscapeTileCard } from '@/molecules/LandscapeRowCollection'
import { LinkViewModel } from '@/services/RenderService'
import { Collection } from '@/types/codegen-contentful'
import { AnalyticsEvent, useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface Props {
  testId?: string
  sneakPeekTitleCollection?: Collection | undefined
}

interface SneakPeek {
  subtitle: string
  href: string
  imageUrl: string
  duration: number
  slug: string
  episodeNumber?: number
  seasonNumber?: number
  guid?: string
}

export const SneakPeekCollection: FC<Props> = ({ testId, sneakPeekTitleCollection }) => {
  const track = useSafeTrack()
  const { t } = useTranslate('watch')

  const handleTracking = () => {
    track('Sneak Peek Rail Item Clicked')
  }

  return (
    <LandscapeRowCollection testId={testId} title={t('sneakPeeks', 'Sneak Peeks')}>
      {sneakPeekTitleCollection?.customData?.map((item: SneakPeek) => {
        if (!item) return null

        const linkModel = buildLinkModel(item)
        if (!linkModel) return null

        return (
          <LazyHydrate key={item?.slug} whenVisible>
            <>
              <LandscapeTileCard className="mb-2 mr-4" linkModel={linkModel} onClick={handleTracking}>
                <PlayButtonThumbnailOverlay />
              </LandscapeTileCard>
              <TitleAndSubtitleDisplay
                className="mr-4"
                title={t('sneakPeek', 'Sneak Peek')}
                subtitle={item.subtitle}
                duration={item.duration}
              />
            </>
          </LazyHydrate>
        )
      })}
    </LandscapeRowCollection>
  )
}

const buildLinkModel = (item: SneakPeek): LinkViewModel | null => {
  return {
    alt: item.subtitle,
    label: item.subtitle,
    imageUrl: item.imageUrl,
    linkUrl: item.href,
    track: {
      eventName: 'Content Link Clicked' as AnalyticsEvent,
      payload: {
        linkUrl: item.href,
        projectSlug: item.slug,
        episodeNumber: item?.episodeNumber ?? 0,
        guid: item.guid ?? '',
        seasonNumber: item.seasonNumber ?? 0,
      },
    },
  }
}
