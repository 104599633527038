import { gql } from '@apollo/client'

export const GET_LIST_CONTENT_QUERY = gql`
  query getListContent($contentStates: [ContentState!]) {
    listContent(contentStates: $contentStates, contentType: EPISODE) {
      id
      guid
      name
      subtitle
      userHasEarlyAccess
      earlyAccessDate
      publiclyAvailableDate
      unavailableReason
      posterLandscapeCloudinaryPath
      episodeNumber
      duration
      source {
        url
        duration
        credits
      }
      watchPosition {
        id
        position
      }
      season {
        id
        seasonNumber
        project {
          id
          name
          slug
          public
          projectType
          metadata {
            contentRating
          }
        }
      }
    }
  }
`
