import React from 'react'
import { Else, If, Then } from 'react-if'
import { AsH3, TitleLG } from '@/atoms/Text'
import { LandscapeRowCollection } from '@/molecules/LandscapeRowCollection'
import { PlaceholderMediaCard } from '@/molecules/MediaCard'
import { useTranslate } from '@/utils/translate/translate-client'

interface PlaceholderLandscapeCollectionProps {
  heading?: string
  hideTextPlaceholders?: boolean
}
export const PlaceholderLandscapeCollection: React.FC<PlaceholderLandscapeCollectionProps> = ({
  heading,
  hideTextPlaceholders,
}) => {
  const { t } = useTranslate('watch')

  return (
    <div>
      <header>
        <If condition={heading}>
          <Then>
            <TitleLG weight="bold" as={AsH3} className="mb-3 md:mb-4">
              {heading}
            </TitleLG>
          </Then>
          <Else>
            <TitleLG weight="bold" as={AsH3} className="mb-3 !text-transparent md:mb-4">
              {heading || t('loading', 'Loading')}
            </TitleLG>
          </Else>
        </If>
      </header>
      <LandscapeRowCollection>
        {Array(5)
          .fill(0)
          .map((v, i) => (
            <PlaceholderMediaCard
              className="mr-4 min-h-[86px] min-w-[155px] lg:min-h-[180px] lg:min-w-[236px]"
              borderRadiusClassName="rounded-lg"
              hideTextPlaceholders={hideTextPlaceholders}
              key={i}
            />
          ))}
      </LandscapeRowCollection>
    </div>
  )
}
