import { FC } from 'react'
import { useInlineExperiment } from '@/experimentation'
import { ContentItem } from '@/page/PageContent/ContentItem'
import { PageDataContext } from '@/services/RenderService'
import { InlineExperimentContentItemsItem } from '@/types/codegen-contentful'

interface Props {
  experimentName: string
  contentItems: InlineExperimentContentItemsItem[]
  pageDataContext: PageDataContext
}

export const InlineExperiment: FC<Props> = ({ contentItems, experimentName, pageDataContext }) => {
  const variations = contentItems.map((item) => item.sys.id)
  const selectedVariationID = useInlineExperiment({
    key: experimentName,
    variations: variations as [string, string, ...string[]],
    active: variations.length > 1,
  })

  // Handle no variations
  if (variations.length === 0) return null

  // Handle only one variation
  if (variations.length === 1) {
    return <ContentItem content={contentItems[0]} pageDataContext={pageDataContext} />
  }

  // Handle selected variation
  const selectedEntry = contentItems.find(({ sys }) => sys.id === selectedVariationID)
  if (selectedEntry) {
    return <ContentItem content={selectedEntry} pageDataContext={pageDataContext} />
  }

  return null
}
