import React, { useCallback } from 'react'
import { InternalLink } from '@/atoms/InternalLink'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { LinkViewModel } from '@/services/RenderService/types'
import { useSafeTrack } from '@/utils/analytics'

interface Props {
  contentLink: LinkViewModel
}

export const SquareTile: React.FC<Props> = ({ contentLink }) => {
  const track = useSafeTrack()

  const handleClick = useCallback(() => {
    if (!contentLink.track) return
    track(contentLink.track?.eventName, contentLink.track?.payload)
  }, [contentLink.track, track])

  const title = contentLink.alt ?? ''

  return (
    contentLink.imageUrl && (
      <div key={contentLink.imageUrl} className="h-full" title={title}>
        <div className="mr-4 h-full">
          <InternalLink onClick={handleClick} href={contentLink.linkUrl}>
            <div className="flex h-[180px] cursor-pointer items-center justify-center rounded-lg bg-gray-900 p-4 duration-[400ms] ease-in-out hover:bg-gray-800">
              <div className="relative h-full w-full">
                <Image
                  alt={title}
                  src={`${contentLink.imageUrl}`}
                  className="m-auto h-fit"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </div>
          </InternalLink>
        </div>
      </div>
    )
  )
}
