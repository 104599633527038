import { isHasTypeName } from '@/services/RenderService'
import { Spacer as SpacerType } from '@/types/codegen-contentful'

interface SpacerProps {
  spacer: SpacerType
}

export function isSpacer(content: unknown): content is SpacerType {
  return isHasTypeName(content) && content.__typename === 'Spacer'
}

export function Spacer(props: SpacerProps) {
  return (
    <div
      style={{
        height: props.spacer.height ?? 'auto',
        width: props.spacer.width ?? 'auto',
      }}
    ></div>
  )
}
